<template>
  <!-- page2 -->
  <div class="width-100 height-100 content" id="card1">
    <audio :src="audiosrc" ref="audio"></audio>
    <div class="yxh none">
      <img src="../../assets/img/90s/yxh2.gif" alt="">
    </div>
    <div class="head none">
      <div class="img">
        <img src="../../assets/img/90s/icon.png" alt="" />
      </div>
      <div class="text">基本介绍</div>
      <div class="company_name">伊利股份SH</div>
    </div>
    <div class="left none">
      <div class="bar none">
        <img src="../../assets/img/90s/bar.png" alt="" />
      </div>
      <ul>
        <li class="none">
          <div class="top">上市时间</div>
          <div class="bottom">1996年03月12日</div>
        </li>
        <li class="none">
          <div class="top">所属交易所</div>
          <div class="bottom">上海证券交易所</div>
        </li>
        <li class="none">
          <div class="top">公司地址</div>
          <div class="bottom">内蒙古</div>
        </li>
      </ul>
    </div>
    <div class="swiper">
      <div
        class="
          swiper-container
          swiper-container-initialized
          swiper-container-horizontal
        "
      >
        <div class="swiper-wrapper">
          <div class="swiper-slide" :style="`background:url(${src1})  no-repeat`">
          </div>
           <div class="swiper-slide" :style="`background:url(${src3})  no-repeat`">
          </div>
          <div class="swiper-slide" :style="`background:url(${src4})  no-repeat`">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep } from "@/utils/common";
import Swiper from "swiper";
import src1 from "../../assets/img/90s/pic2.png"
import src3 from "../../assets/img/90s/pic.png"
import src4 from "../../assets/img/90s/pic4.png"


export default {
  name: "TwoComponent",
  props: ["cardData", "tempAduioArr"],
  data: function () {
    return {
      audiosrc: "",
      swiper: null,
      src1:src1,
      src3:src3,
      src4:src4,
    };
  },
  mounted() {
    new Swiper(".swiper-container", {
      speed:1000,
      autoplay: true,
      slidesPerView: 3,
      slidesPerGroup: 1,
      spaceBetween: 50,
      centeredSlides: true,
      loop: true,
    });
  },
  methods: {
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    playOut() {
      this.txt.goOut.restart();
    },
    // 页面card一个接一个播放
    async playCardStart() {
      console.log(this.tempAduioArr[1]);
      this.audiosrc = this.tempAduioArr[1].audio_link;
      this.$nextTick(() => {
        this.$refs["audio"].play();
      });
      await this.playPage1();

      setTimeout(() => {
        // $("#card1").addClass("animate__animated animate__fadeOut");
        this.$emit("playEnd");     
      }, this.getTime().pageLen * 1000);
      
    },
    async playPage1() {
      $(".head").removeClass("none");
      $(".head").addClass("animate__animated animate__bounceInDown");
      await sleep(1000);
      $(".left").removeClass("none");
      $(".left .bar").removeClass("none");
      $(".left .bar").addClass("animate__animated animate__fadeInUp");
      await sleep(500);
      $(".left li").eq(0).removeClass("none");
      $(".left li").eq(0).addClass("animate__animated animate__fadeInUpBig");
      await sleep(500);
      $(".left li").eq(1).removeClass("none");
      $(".left li").eq(1).addClass("animate__animated animate__fadeInUpBig");
      await sleep(500);
      $(".left li").eq(2).removeClass("none");
      $(".left li").eq(2).addClass("animate__animated animate__fadeInUpBig");
      await sleep(1000);
      $(".swiper-container").css("opacity", 1);
      $(".swiper-container").addClass("animate__animated animate__fadeInRight");
       $(".yxh").removeClass("none");

    },
    getTime() {
      // 默认返回5秒
      let time = 5;
      let audioTime = Math.ceil(this.tempAduioArr[1].audio_length);
      if (audioTime > time) {
        time = audioTime;
      }
      return {
        audio_link: this.tempAduioArr[1].audio_link,
        pageLen: time,
      };
    },
  },
};
</script>
<style scoped lang="less">
@import url("~swiper/css/swiper.min.css");
#card1 {
  background: url("../../assets/img/90s/bg.png");
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
    height: 100vh;
  overflow: hidden;
  .head {
    display: flex;
    position: absolute;
    top: 95px;
    left: 69px;
    color: #fff;
    justify-content: space-around;
    height: 60px;
    line-height: 53px;
    .img {
      width: 78px;
      height: 53px;
      margin-right: 34px;
    }
    .text {
      width: 208px;
      height: 52px;
      font-size: 52px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      margin-right: 14px;
    }
    .company_name {
      width: 265px;
      height: 60px;
      background: #3875f4;
      border-radius: 16px 16px 16px 4px;
      font-size: 40px;
      font-weight: 800;
      text-align: center;
    }
  }
  .left {
    overflow-x: hidden;
    overflow-y: hidden;
    height: 500px;
    // width: 50%;

    .bar {
      position: absolute;
      left: 181px;
      top: 422px;
      height: 450px;
      overflow-x: hidden;
      overflow-y: hidden;
      img{
        overflow-x: hidden;
    overflow-y: hidden;
      }
    }
    ul {
      position: absolute;
      top: 357px;
      left: 271px;
      height: 900px;
      overflow-x: hidden;
      overflow-y: hidden;
      li {
        color: #fff;
        width: 476px;
        height: 220px;
        padding: 26px 58px 24px 25px;
        box-sizing: border-box;
        margin-bottom: 48px;
        overflow-x: hidden;
      overflow-y: hidden;
        .top {
          // width: 150px;
          height: 28px;
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          opacity: 0.6;
          margin-bottom: 18px;
        }
        .bottom {
          // width: 328px;
          height: 62px;
          font-size: 44px;
          font-family: Helvetica;
          font-weight: bold;
          color: #ffffff;
          opacity: 0.8;
        }
      }
      li:first-of-type,
      li:nth-of-type(3) {
        width: 416px;
        height: 160px;
        background: linear-gradient(180deg, #33425b 0%, #14161e 100%);
        box-shadow: 0px 4px 20px rgba(6, 19, 29, 0.6);
        border-radius: 16px;
      }
      li:nth-of-type(2) {
        width: 416px;
        height: 160px;
        background: linear-gradient(180deg, #98a9ff 0%, #2365ec 100%);
        box-shadow: 0px 4px 20px rgba(6, 19, 29, 0.6);
        opacity: 1;
        border-radius: 16px;
      }
    }
  }
  .swiper {
    overflow-x: hidden;
    overflow-y: hidden;
    width: 1000px;
    height: 500px;
    position: absolute;
    top: 455px;
    right: 160px;
    .swiper-container {
      width: 1200px;
      height: 523px;
      padding-top: 50px;
      opacity: 0;
    }
  }
  .swiper-slide {
    height: 282px;
    // background-color:red;
    transform: scale(1);
    transition: all 0.3s;
    background-size: 100% 100%!important;

  }
  .swiper-slide img {
    width: 100%;
    height: auto;
  }
  .swiper-slide-active {
    transform: scale(1.5);
    z-index: 999;
  }
  .swiper-slide-prev {
    opacity: 0.5;
  }
  .swiper-slide-next {
    opacity: 0.5;
  }
  .none {
    display: none;
  }
  .yxh {
        width: 20%;
    height: 43%;
    position: absolute;
    right: -3%;
    bottom: -4%;
    z-index: 9999;
  }
}
</style>

