<template>
  <!-- page5 -->
  <div class="width-100 height-100" id="card1">
    <audio :src="audiosrc" ref="audio"></audio>
    <div class="yxh none">
      <img src="../../assets/img/90s/yxh2.gif" alt="" />
    </div>
    <div class="head none">
      <div class="img">
        <img src="../../assets/img/90s/icon.png" alt="" />
      </div>
      <div class="text">净利润</div>
      <div class="company_name">伊利股份</div>
    </div>
    <div class="content none">
      <div class="dec">
        <div>年报归母净利润</div>
        <div class="mark"><span id="num1">87.05</span><span>亿元</span></div>
        <div>同比增长</div>
        <div class="mark"><span id="num2">22.98</span><span>%</span></div>
      </div>
      <div id="chart1"></div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep } from "@/utils/common";
export default {
  name: "TwoComponent",
  data: function () {
    return {
      audiosrc: "",
      market1: [
        { label: "2021年报", value: 87.05 },
        { label: "2020年报", value: 70.78 },
        { label: "2019年报", value: 69.34 },
        { label: "2018年报", value: 64.4 },
        { label: "2017年报", value: 60.01 },
      ],
    };
  },
  props: ["cardData", "tempAduioArr"],
  computed: {},
  // components: { barChartHorizen },
  mounted() {},
  methods: {
    playNumVaryIn(id, fixed) {
      this.number = anyElement({
        targets: document.querySelector(id),
        duration: 1200,
        toFixed: fixed == undefined ? 2 : fixed,
        easing: "easeOutQuint",
        inEffect: "numVaryIn",
        outEffect: "fadeOut",
      });
      this.number.goIn.restart();
    },
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    playOut() {
      this.txt.goOut.restart();
    },
    // playIn1(refName) {
    //   this.$refs[refName].BarChart(this.market1, {
    //     x: (d) => d.value,
    //     y: (d) => d.label,
    //     width: 1407,
    //     height: 500,
    //     marginLeft: 180,
    //     marginRight: 80,
    //     duration: 400,
    //     delay: 400,
    //     yPadding: 0.3,
    //     ease: "easeBack",
    //     // numberSuffix: "家",
    //     numberPosition: "outside",
    //   });
    // },
    // 页面card一个接一个播放
    async playCardStart() {
      this.audiosrc = this.tempAduioArr[5].audio_link;
      this.$nextTick(() => {
        this.$refs["audio"].play();
      });

      setTimeout(() => {
        this.$emit("playEnd");
      }, this.getTime().pageLen * 1000);

      await this.playPage1();
    },
    getTime() {
      // 默认返回5秒
      let time = 5;
      let audioTime = Math.ceil(this.tempAduioArr[5].audio_length);
      if (audioTime > 5) {
        time = audioTime;
      }
      return {
        audio_link: this.tempAduioArr[5].audio_link,
        pageLen: time,
      };
    },
    async playPage1() {
      $(".head").removeClass("none");
      $(".head").addClass("animate__animated animate__fadeInDownBig");
      await sleep(1000);
      $(".content").removeClass("none");
      $(".content").addClass("animate__animated animate__fadeIn");
      this.playNumVaryIn("#num1", 2);
      this.playNumVaryIn("#num2", 2);
      await sleep(1000);
      this.loadChart1();
      $(".yxh").removeClass("none");
    },
    loadChart1() {
      let myChart = this.$echarts.init(document.getElementById("chart1"));
      // 绘制图表
      myChart.setOption({
        grid: {
          left: "14%",
        },
        xAxis: {
          type: "value",
          axisLabel: {
            show: true,
            color: "#B1BBD0",
            fontSize: 28,
            opacity: 0.5,
          },
          splitLine: {
            lineStyle: {
              color: "#B1BBD0",
              opacity: 0.5,
            },
          },
        },
        yAxis: {
          type: "category",
          axisLine: { show: false },
          axisLabel: {
            show: true,
            color: "#fff",
            fontSize: 28,
            margin: 50,
          },
          axisTick: { show: false },
          splitLine: { show: false },
          data: ["2017年报", "2018年报", "2019年报", "2020年报", "2021年报"],
        },
        series: [
          {
            name: "Cost",
            type: "bar",
            // stack: "Total",
            label: {
              show: false,
            },
            data: [60.1, 64.4, 69.34, 70.78, 87.05],
            barWidth: 80,
            // animationDuration: 3000,
            // animationEasing: "cubicInOut",

            // delay: function (index, count) {
            //   return Math.random() * 1000;
            // },
            color: {
              type: "linear",
              x: 1, //右
              y: 0, //下
              x2: 0, //左
              y2: 0, //上
              colorStops: [
                {
                  offset: 0,
                  color: "#2365EB", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#96A8FF", // 100% 处的颜色
                },
              ],
            },
          },
        ],
      });
    },
  },
};
</script>
<style scoped lang="less">
#card1 {
  background: url("../../assets/img/90s/bg.png");
  box-sizing: border-box;
  height: 100vh;
  overflow: hidden;
  .none {
    display: none;
  }
  .yxh {
    width: 20%;
    height: 43%;
    position: absolute;
    right: -3%;
    bottom: -4%;
    z-index: 9999;
  }
  .head {
    display: flex;
    position: absolute;
    top: 95px;
    left: 69px;
    color: #fff;
    justify-content: space-around;
    height: 60px;
    line-height: 53px;
    .img {
      width: 78px;
      height: 53px;
      margin-right: 34px;
    }
    .text {
      width: 156px;
      height: 52px;
      font-size: 52px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      margin-right: 14px;
    }
    .company_name {
      width: 208px;
      height: 60px;
      background: #3875f4;
      border-radius: 16px 16px 16px 4px;
      font-size: 40px;
      font-weight: 800;
      text-align: center;
    }
  }
  .content {
    position: absolute;
    left: 180px;
    top: 230px;
    width: 1560px;
    height: 730px;
    background: linear-gradient(180deg, #33475d 0%, #11121a 100%);
    box-shadow: 0px 10px 30px rgba(6, 19, 29, 0.6);
    border-radius: 24px;
    .dec {
      display: flex;
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.89;
      position: absolute;
      top: 64px;
      left: 395px;
      height: 67px;
      line-height: 67px;
      .mark {
        font-size: 46px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ff8d1a;
        opacity: 0.89;
        margin-left: 15px;
        margin-right: 82px;
        line-height: 57px;
      }
    }
    #chart1 {
      width: 1500px;
      height: 580px;
      position: absolute;
      left: 51px;
      bottom: 40px;
    }
    .c_bar1 {
      position: absolute;
      left: 80px;
      top: 182px;
      width: 1407px;
      height: 500px;
      margin-bottom: 20px;
      /deep/ .y_label {
        font-size: 18px;
      }
      /deep/ .axis_x {
        font-size: 24px;
        .tick_line,
        .domain {
          display: none;
        }
        .text {
          fill: #ffffff;
        }
      }
      /deep/ .axis_y {
        font-size: 18px;
        .tick_line,
        .domain {
          display: none;
        }
        .text {
          fill: #fff;
          font-size: 32px;
        }
        .tick_long_line {
          stroke: rgba(0, 0, 0, 0.15);
        }
      }
      /deep/ .bar_group rect {
        fill: url(#radial1);
      }
      /deep/ .numbers {
        // display: none;
      }
    }
  }
}
</style>

