<template>
  <!-- page5 -->
  <div class="width-100 height-100" id="card1">
    <audio :src="audiosrc" ref="audio"></audio>
    <div class="yxh none">
      <img src="../../assets/img/90s/yxh2.gif" alt="" />
    </div>
    <div class="head none">
      <div class="img">
        <img src="../../assets/img/90s/icon.png" alt="" />
      </div>
      <div class="text">主营业务</div>
      <div class="company_name">伊利股份</div>
    </div>
    <div class="content none">
      <div class="chart">
        <rose-chart
          ref="refRoseChart2"
          id="refRoseChart2"
          customClass="c_rose2"
        >
        </rose-chart>
      </div>
      <div class="legend none">
        <ul>
          <li class="none">
            <div class="xl xl1">
              <div class="text">2021年报</div>
            </div>
            <div class="proportion">收入</div>
          </li>
          <li class="none">
            <div class="xl">
              <div class="icon icon1"></div>
              <div class="text">液体乳</div>
            </div>
            <div class="proportion">
              <span id="num1">849.11</span><span>亿</span>
            </div>
          </li>
          <li class="none">
            <div class="xl">
              <div class="icon icon2"></div>
              <div class="text">奶粉及奶制品</div>
            </div>
            <div class="proportion">
              <span id="num2">162.09</span><span>亿</span>
            </div>
          </li>
          <li class="none">
            <div class="xl">
              <div class="icon icon3"></div>
              <div class="text">冷饮产品系列</div>
            </div>
            <div class="proportion">
              <span id="num3">71.61</span><span>亿</span>
            </div>
          </li>
          <li class="none">
            <div class="xl">
              <div class="icon icon4"></div>
              <div class="text">其他</div>
            </div>
            <div class="proportion">
              <span id="num4">1.82</span><span>亿</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import roseChart from "../../components/roseChart";
import { sleep } from "@/utils/common";
export default {
  name: "TwoComponent",
  data: function () {
    return {
      audiosrc: "",
      population2: [
        { name: "液体乳", value: 849.11 },
        { name: "奶粉及奶制品", value: 162.09 },
        { name: "冷饮产品系列", value: 71.61 },
        { name: "其他", value: 1.82 },
      ],
    };
  },
  props: ["cardData", "tempAduioArr"],
  components: { roseChart },
  mounted() {},
  methods: {
    playNumVaryIn(id, fixed) {
      this.number = anyElement({
        targets: document.querySelector(id),
        duration: 2000,
        toFixed: fixed == undefined ? 2 : fixed,
        easing: "easeOutQuint",
        inEffect: "numVaryIn",
        outEffect: "fadeOut",
      });
      this.number.goIn.restart();
    },
    playIn1(refName) {
      this.$refs[refName].DonutChart(this.population2, {
        name: (d) => d.name,
        value: (d) => d.value,
        width: 660,
        height: 660,
        donutRatioRange: [0.2, 0.65, 0.9], //空心内圈的半径占比，最小半径占比和最大半径占比
        colors: ["#5481F4", "#E08B22", "#A450F9", "#F9E04C"],
        duration: 1000,
        delay: 60,
        ease: "easeBackOut",
        valueType: "relative",
        isRotateLabel: true,
        labelOffset: 42,
      });
    },
    // 页面card一个接一个播放
    async playCardStart() {
      this.audiosrc = this.tempAduioArr[6].audio_link;
      this.$nextTick(() => {
        this.$refs["audio"].play();
      });

      setTimeout(() => {
        this.$emit("playEnd");
      }, this.getTime().pageLen * 1000);

      await this.playPage1();
    },
    getTime() {
      // 默认返回5秒
      let time = 5;
      let audioTime = Math.ceil(this.tempAduioArr[6].audio_length);
      if (audioTime > 5) {
        time = audioTime;
      }
      return {
        audio_link: this.tempAduioArr[6].audio_link,
        pageLen: time,
      };
    },
    async playPage1() {
      $(".head").removeClass("none");
      $(".head").addClass("animate__animated animate__fadeInDownBig");
      await sleep(1000);
      $(".content").removeClass("none");
      $(".content").addClass("animate__animated animate__fadeIn");
      await sleep(1000);
      $(".legend").removeClass("none");
      $(".legend").addClass("animate__animated animate__fadeInRight");
      this.playIn1("refRoseChart2");
      await sleep(500);
      this.playNumVaryIn("#num1", 2);
      this.playNumVaryIn("#num2", 2);
      this.playNumVaryIn("#num3", 2);
      this.playNumVaryIn("#num4", 2);
      $(".yxh").removeClass("none");
    },
  },
};
</script>
<style scoped lang="less">
#card1 {
  background: url("../../assets/img/90s/bg.png");
  box-sizing: border-box;
  height: 100vh;
  overflow: hidden;
  .none {
    display: none;
  }
  .yxh {
    width: 20%;
    height: 43%;
    position: absolute;
    right: -3%;
    bottom: -4%;
    z-index: 9999;
  }
  .head {
    display: flex;
    position: absolute;
    top: 95px;
    left: 69px;
    color: #fff;
    justify-content: space-around;
    height: 60px;
    line-height: 53px;
    .img {
      width: 78px;
      height: 53px;
      margin-right: 34px;
    }
    .text {
      width: 208px;
      height: 52px;
      font-size: 52px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      margin-right: 14px;
    }
    .company_name {
      width: 208px;
      height: 60px;
      background: #3875f4;
      border-radius: 16px 16px 16px 4px;
      font-size: 40px;
      font-weight: 800;
      text-align: center;
    }
  }
  .content {
    position: absolute;
    left: 180px;
    top: 230px;
    width: 1560px;
    height: 730px;
    background: linear-gradient(180deg, #33475d 0%, #11121a 100%);
    box-shadow: 0px 10px 30px rgba(6, 19, 29, 0.6);
    border-radius: 24px;
    .legend {
      position: absolute;
      top: 164px;
      left: 882px;
      li {
        display: flex;
        width: 467px;
        height: 98px;
        line-height: 12px;
        justify-content: space-between;
        .xl {
          display: flex;
          justify-content: space-between;
          .icon {
            width: 17px;
            height: 17px;
            border-radius: 50%;
            margin-right: 20px;
          }
          .text {
            height: 36px;
            color: #ffffff;
            opacity: 0.8;
            font-size: 36px;
            font-family: Source Han Sans CN;
            font-weight: 400;
          }
          .icon1 {
            background-color: #5481f4;
          }
          .icon2 {
            background-color: #e08b22;
          }
          .icon3 {
            background-color: #a450f9;
          }
          .icon4 {
            background-color: #f9e04c;
          }
        }
        .xl5 {
          width: 120px;
          .text {
            width: 96px;
          }
        }

        .proportion {
          font-size: 36px;
          font-family: Helvetica;
          color: #ffffff;
        }
      }
      li:first-of-type {
        font-size: 36px;
        .proportion {
          color: #ff8d1a;
        }
        .xl1 {
          width: 160px;
          .text {
            width: 166px;
            color: #ff8d1a;
          }
        }
      }
    }
    .c_rose2 {
      position: absolute;
      left: 110px;
      top: 42px;
      display: inline-block;
      /deep/ .donut_txt1 {
        font-size: 36px;
      }
      // /deep/ g:nth-of-type(2) text:first-of-type .donut_txt1 {
      // x:117px;
      //  y: 0.65em !important;
      //  transform: translate(27px, 10px);
      // }
      /deep/ .donut_txt2 {
        display: none;
      }
      /deep/ .top3 {
        display: none;
      }
    }
  }
}
</style>

