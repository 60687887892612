<template>
  <!-- page2 -->
  <div class="width-100 height-100 card1">
    <audio :src="audiosrc" ref="audio"></audio>
    <div class="head none">
      <div class="img">
        <img src="../../assets/img/90s/icon.png" alt="" />
      </div>
      <div class="text">公司高管</div>
      <div class="company_name">伊利股份</div>
    </div>
    <div class="balls">
      <div class="ball1 Ball">
        公司高管
        <div class="img_box"></div>
      </div>
      <div class="ball2 ball Ball">
        <div>李峰</div>
        <div>43岁/董事</div>
      </div>
      <div class="ball3 Ball2 Ball">
        <div>潘刚</div>
        <div>52岁/董事长</div>
      </div>
      <div class="ball4 Ball3 Ball">
        <div>张俊平</div>
        <div>60岁/董事</div>
      </div>
      <div class="ball5 Ball4 Ball">
        <div>闫俊荣</div>
        <div>50岁/执行董事</div>
      </div>
      <div class="ball6 Ball2 Ball"></div>
      <div class="ball7 Ball3 Ball"></div>
      <div class="ball8 Ball4 Ball"></div>
      <div class="ball9 Ball2 Ball"></div>
      <div class="ball10 Ball3 Ball"></div>
      <div class="ball11 Ball4 Ball"></div>
      <div class="ball12 Ball2 Ball"></div>
      <div class="ball13 Ball3 Ball"></div>
      <div class="ball14 Ball4 Ball"></div>
      <div class="ball15 Ball2 Ball"></div>
      <div class="ball16 Ball2 Ball"></div>
      <div class="ball17 Ball2 Ball"></div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep } from "@/utils/common";

export default {
  name: "TwoComponent",
  data: function () {
    return {
      audiosrc: "",
    };
  },
  props: ["cardData", "tempAduioArr"],
  computed: {},
  watch: {},
  methods: {
    playNumVaryIn(id, fixed) {
      this.number = anyElement({
        targets: document.querySelector(id),
        duration: 1200,
        toFixed: fixed == undefined ? 2 : fixed,
        easing: "easeOutQuint",
        inEffect: "numVaryIn",
        outEffect: "fadeOut",
      });
      this.number.goIn.restart();
    },
    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      console.log(_outEffect+'_outEffect');
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },
    playOut() {
      this.txt.goOut.restart();
    },
    // 页面card一个接一个播放
    async playCardStart() {
      console.log(this.tempAduioArr[2]);

      if (!this.tempAduioArr[2]) return;

      this.audiosrc = this.tempAduioArr[2].audio_link;
      this.$nextTick(() => {
        this.$refs["audio"].play();
      });

      setTimeout(() => {
        this.$emit("playEnd");
      }, this.getTime().pageLen * 1000);
      await this.playPage1();
    },
    getTime() {
      // 默认返回5秒
      let time = 3;
      if (!this.tempAduioArr[2]) return;
      let audioTime = Math.ceil(this.tempAduioArr[2].audio_length);
      if (audioTime > 3) {
        time = audioTime;
      }
      return {
        audio_link: this.tempAduioArr[2].audio_link,
        pageLen: time,
      };
    },
    async playPage1() {
      $(".head").removeClass("none");
      $(".head").addClass("animate__animated animate__fadeInDownBig");
      await sleep(1000);
      // $(".ball1").css("opacity", "1");
      // $(".ball1").addClass("animate__animated animate__bounceIn");
      // await sleep(100);
      // $(".ball").css("opacity", "1");
      // $(".ball").addClass("animate__animated animate__zoomIn");
      // await sleep(200);
      // $(".Ball2").css("opacity", "1");
      // $(".Ball2").addClass("animate__animated animate__zoomIn");
      // await sleep(300);
      // $(".Ball3").css("opacity", "1");
      // $(".Ball3").addClass("animate__animated animate__zoomIn");
      // await sleep(400);
      // $(".Ball4").css("opacity", "1");
      this.$anime({
        targets: ".Ball",
        // translateZ: 250,
        // direction: "alternate",
        opacity:1,
        scale:[0.1,1],
        easing: "easeInOutBack",
        duration:800,
        delay:this.$anime.stagger(100)
      });
    },
  },
};
</script>
<style scoped lang="less">
.card1 {
  background: url("../../assets/img/90s/bg.png");
  box-sizing: border-box;
  .none {
    display: none;
  }
  .head {
    display: flex;
    position: absolute;
    top: 95px;
    left: 69px;
    color: #fff;
    justify-content: space-around;
    height: 60px;
    line-height: 53px;
    .img {
      width: 78px;
      height: 53px;
      margin-right: 34px;
    }
    .text {
      width: 208px;
      height: 52px;
      font-size: 52px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      margin-right: 14px;
    }
    .company_name {
      width: 208px;
      height: 60px;
      background: #3875f4;
      border-radius: 16px 16px 16px 4px;
      font-size: 40px;
      font-weight: 800;
      text-align: center;
    }
  }
  .balls {
    width: 999px;
    height: 754px;
    position: absolute;
    top: 163px;
    left: 469px;
    color: #ffffff;
    font-family: Source Han Sans CN;
    font-weight: 800;
    box-sizing: border-box;
    .ball1 {
      width: 325px;
      height: 325px;
      border-radius: 50%;
      background: linear-gradient(180deg, #9aaaff 0%, #2163eb 100%);
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 219px;
      left: 329px;
      font-size: 52px;
      z-index: 999;
      box-shadow: 0px 5px 30px rgba(19, 14, 29, 0.6);
      opacity: 0;
    }
    .ball {
      box-sizing: border-box;
      font-size: 24px;
      text-align: center;
      z-index: 9999;
      div:first-of-type {
        font-size: 40px;
      }
      opacity: 0;
      box-shadow: 0px 5px 30px rgba(19, 14, 29, 0.6);
    }
    .Ball2 {
      box-sizing: border-box;
      font-size: 24px;
      text-align: center;
      z-index: 9999;
      div:first-of-type {
        font-size: 40px;
      }
      opacity: 0;
      box-shadow: 0px 5px 30px rgba(19, 14, 29, 0.6);
    }
    .Ball3 {
      box-sizing: border-box;
      font-size: 24px;
      text-align: center;
      z-index: 9999;
      div:first-of-type {
        font-size: 40px;
      }
      opacity: 0;
      box-shadow: 0px 5px 30px rgba(19, 14, 29, 0.6);
    }
    .Ball4 {
      box-sizing: border-box;
      font-size: 24px;
      text-align: center;
      z-index: 9999;
      div:first-of-type {
        font-size: 40px;
      }
      opacity: 0;
      box-shadow: 0px 5px 30px rgba(19, 14, 29, 0.6);
    }
    .ball2 {
      width: 219px;
      height: 219px;
      border-radius: 50%;
      background: linear-gradient(180deg, #9aaaff 0%, #2163eb 100%);
      background-size: cover;
      position: absolute;
      top: 0px;
      right: 235px;
      line-height: 43px;
      padding: 55px;
    }
    .ball3 {
      width: 240px;
      height: 240px;
      border-radius: 50%;
      background: linear-gradient(180deg, #9aaaff 0%, #2163eb 100%);
      background-size: cover;
      position: absolute;
      top: 124px;
      left: 62px;
      line-height: 54px;
      padding: 53px;
    }
    .ball4 {
      width: 209px;
      height: 209px;
      border-radius: 50%;
      background: linear-gradient(180deg, #9aaaff 0%, #2163eb 100%);
      background-size: cover;
      position: absolute;
      top: 348px;
      right: 86px;
      line-height: 63px;
      padding: 44px;
    }
    .ball5 {
      width: 240px;
      height: 240px;
      border-radius: 50%;
      background: linear-gradient(180deg, #9aaaff 0%, #2163eb 100%);
      background-size: cover;
      position: absolute;
      bottom: 0px;
      left: 139px;
      line-height: 65px;
      padding: 40px;
    }
    .ball6 {
      width: 62px;
      height: 62px;
      border-radius: 50%;
      background: linear-gradient(180deg, #9aaaff 0%, #2163eb 100%);
      background-size: cover;
      position: absolute;
      top: 392px;
      left: 217px;
    }
    .ball7 {
      width: 101px;
      height: 101px;
      border-radius: 50%;
      background: linear-gradient(180deg, #9aaaff 0%, #2163eb 100%);
      background-size: cover;
      position: absolute;
      top: 74px;
      left: 350px;
    }
    .ball8 {
      width: 67px;
      height: 67px;
      border-radius: 50%;
      background: linear-gradient(180deg, #9aaaff 0%, #2163eb 100%);
      background-size: cover;
      position: absolute;
      top: 262px;
      right: 261px;
    }
    .ball9 {
      width: 109px;
      height: 109px;
      border-radius: 50%;
      background: linear-gradient(360deg, #3f4cea 0%, #9b6ed9 100%);
      background-size: cover;
      position: absolute;
      top: 41px;
      right: 31px;
    }
    .ball17 {
      width: 160px;
      height: 160px;
      border-radius: 50%;
      background: linear-gradient(360deg, #3f4cea 0%, #9b6ed9 100%);
      background-size: cover;
      position: absolute;
      top: 547px;
      right: 153px;
    }
    .ball10 {
      width: 67px;
      height: 67px;
      border-radius: 50%;
      background: linear-gradient(360deg, #3f4cea 0%, #9b6ed9 100%);
      background-size: cover;
      position: absolute;
      top: 581px;
      right: 159px;
    }
    .ball11 {
      width: 101px;
      height: 101px;
      border-radius: 50%;
      background: linear-gradient(360deg, #3f4cea 0%, #9b6ed9 100%);
      background-size: cover;
      position: absolute;
      top: 231px;
      right: 79px;
    }
    .ball12 {
      width: 101px;
      height: 101px;
      border-radius: 50%;
      background: linear-gradient(180deg, #9aaaff 0%, #2163eb 100%);
      background-size: cover;
      position: absolute;
      bottom: 43px;
      right: 295px;
    }
    .ball13 {
      width: 73px;
      height: 73px;
      border-radius: 50%;
      background: linear-gradient(180deg, #9aaaff 0%, #2163eb 100%);
      background-size: cover;
      position: absolute;
      bottom: 43px;
      right: 484px;
    }
    .ball15 {
      width: 73px;
      height: 73px;
      border-radius: 50%;
      background: linear-gradient(360deg, #3f4cea 0%, #9b6ed9 100%);
      background-size: cover;
      position: absolute;
      bottom: 43px;
      left: 16px;
    }
    .ball16 {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: linear-gradient(360deg, #3f4cea 0%, #9b6ed9 100%);
      background-size: cover;
      position: absolute;
      left: 249px;
      top: 86px;
    }
    .ball17 {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: linear-gradient(360deg, #3f4cea 0%, #9b6ed9 100%);
      background-size: cover;
      position: absolute;
      bottom: 40px;
      left: 625px;
    }
    .ball14 {
      width: 101px;
      height: 101px;
      border-radius: 50%;
      background: linear-gradient(360deg, #3f4cea 0%, #9b6ed9 100%);
      background-size: cover;
      position: absolute;
      top: 370px;
      left: 0px;
    }
  }
}
</style>

