<template>
  <div class="width-100 height-100 max_box">
    <audio src="audio/90s.mp3" ref="bgAudio"></audio>
    <component
      :is="currentView"
      :tempAduioArr="tempAduioArr"
      ref="coms"
      @playEnd="playNext()"
    ></component>
  </div>
</template>
<script>
import $ from "jquery";
import { GetParams2 } from "../../utils/common";
import ApiServe from "@/api/index";
import { sleep } from "@/utils/common";
import page1 from "./page1.vue";
import page2 from "./page2.vue";
import page3 from "./page3.vue";
import page4 from "./page4.vue";
import page5 from "./page5.vue";
import page6 from "./page6.vue";
import page7 from "./page7.vue";
import page8 from "./page8.vue";
import page9 from "./page9.vue";
import page10 from "./page10.vue";
import page11 from "./page11.vue";
import page12 from "./page12.vue";
import page13 from "./page13.vue";
import page14 from "./page14.vue";
export default {
  name: "KnowListCompIn90s",
  components: {
    page1,
    page2,
    page3,
    page4,
    page5,
    page6,
    page7,
    page8,
    page9,
    page10,
    page11,
    page12,
    page13,
    page14,
  },
  data() {
    return {
      audioSrcList: [],
      audioTimeList: [],
      device_id: null,
      recordtimeAll: null,
      // device_id:null,
      id: "",
      model_id: 83,
      model_name: "",
      model_category: "",
      url_extra_params: null,
      ip: "",
      port: "",
      offset_x: "",
      offset_y: "",
      width: "",
      height: "",
      source: "",
      currentIndex: 0,
      pageSortArr: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,14],
      // pageSortArr: [7],
      pageTimeArr: [], //记录每个页面的时间与旁白时间进行比较
      tempAduioArr: [], //记录接口给的初始旁白data
      aduioData: [], //旁白的数据
    };
  },
  created() {
    this.id = GetParams2().id || null;
    this.model_id = GetParams2().model_id || 1;
    this.model_name = GetParams2().model_name || null;
    this.model_category = GetParams2().model_category || null;
    this.url_extra_params = GetParams2().url_extra_params || null;
    this.ip = GetParams2().ip || null;
    this.port = GetParams2().port || null;
    this.offset_x = GetParams2().offset_x || null;
    this.offset_y = GetParams2().offset_y || null;
    this.width = GetParams2().width || null;
    this.height = GetParams2().height || null;
    this.source = GetParams2().source || null;
    this.getAudio();
  },
  computed: {
    allReady() {
      const { tempAduioArr } = this;
      return {
        tempAduioArr,
      };
    },
    newIpPort() {
      if (this.source == "ali") {
        return "https://recordvideoali.wedengta.com";
      } else {
        return "https://recordvideo.wedengta.com";
      }
    },
    currentView() {
      let currentPage = this.pageSortArr[this.currentIndex];
      if (currentPage == 1) {
        return page1;
      }
      if (currentPage == 2) {
        return page2;
      }
      if (currentPage == 3) {
        return page3;
      }
      if (currentPage == 4) {
        return page4;
      }
      if (currentPage == 5) {
        return page5;
      }
      if (currentPage == 6) {
        return page6;
      }
      if (currentPage == 7) {
        return page7;
      }
      if (currentPage == 8) {
        return page8;
      }
      if (currentPage == 9) {
        return page9;
      }
      if (currentPage == 10) {
        return page10;
      }
      if (currentPage == 11) {
        return page11;
      }
      if (currentPage == 12) {
        return page12;
      }
      if (currentPage == 13) {
        return page13;
      }
      if (currentPage == 14) {
        return page14;
      }
      return page1;
    },
  },
  watch: {
    allReady(val) {
      if (this.tempAduioArr.length > 0) {
        console.log(this.tempAduioArr);
        if (this.ip && this.port) {
          const params = {
            ip: this.ip,
            port: this.port,
            offset_x: this.offset_x,
            offset_y: this.offset_y,
            width: this.width,
            height: this.height,
          };
          ApiServe.recordstartPc(this.newIpPort, params)
            .then(({ code }) => {
              this.load();
            })
            .catch((err) => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
              this.load();
            });
        } else {
          // alert("没获取到ip,port");
          this.load();
        }
      }
    },
  },
  mounted() {},
  methods: {
    // 获取旁白
    getAudio() {
      let params = {
        model_id: 84,
      };
      if (this.url_extra_params) {
        params = {
          model_id: 84,
          url_extra_params: encodeURIComponent(this.url_extra_params),
        };
      }
      ApiServe.getVideoDataPC(params)
        .then(({ data, code }) => {
          if (code == 200) {
            this.tempAduioArr = data;
          }
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
          }
        });
    },
    setAudioData(pageTimeArr) {
      //  页面与旁白必须一对一
      let result = [];
      let timeIndex = 0;
      let len = pageTimeArr.length;
      for (let i = 0; i < len; i++) {
        let { audio_link, pageLen } = pageTimeArr[i];
        result.push({
          audioLink: audio_link,
          timeIndex: timeIndex,
          audioLen: pageLen,
        });
        //下一个起始需要加上总时长
        timeIndex += pageTimeArr[i].pageLen;
      }
      return result;
    },
    endRecord() {
      this.aduioData = this.setAudioData(this.pageTimeArr);

      let totalTime = 0;
      this.pageTimeArr.map((item) => {
        totalTime += item.pageLen;
      });
      let filterList = this.aduioData.filter((item) => item.audioLink != "");

      const data = {
        id: this.id,
        model_id: 83,
        videoTotalTime: totalTime,
        videmBgm: "https://res.idtcdn.com/Cube/video-config/con-ec094cc4.mp3",
        audiosOrderList: filterList,
      };
      ApiServe.recordstopPc(this.newIpPort, { ip: this.ip, port: this.port })
        .then(() => {
          ApiServe.combineaudioswithbgm(
            this.newIpPort,
            this.ip,
            this.port,
            data
          )
            .then(({ data }) => {
              // data.task_id = this.id;
              // // 更新视频记录
              // ApiServe.updateVideoInfo(data);
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            })
            .catch(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        })
        .catch(() => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    },
    // 页面card一个接一个播放
    async load() {
      this.$refs.bgAudio.volume = 0.5
      this.$refs.bgAudio.play();
      await sleep(3000)
      this.$nextTick(() => {
         this.$refs.bgAudio.volume = 0.5
      this.$refs.bgAudio.play();
        this.$refs.coms.playCardStart();
      });

      //记录第一页的时间
      this.pageTimeArr.push(this.getCurrentPageTime());
    },
    async playNext() {
      if (this.currentIndex == this.pageSortArr.length - 1) {
        const pageTime = this.getCurrentPageTime();

        setTimeout(() => {
          this.endRecord();
        }, pageTime.pageLen * 1000);
        return;
      }

      this.currentIndex++;

      this.$nextTick(() => {
        this.$refs.coms.playCardStart();
        this.pageTimeArr.push(this.getCurrentPageTime());
      });
    },
    getCurrentPageTime() {
      let obj = this.$refs.coms.getTime();
      return obj;
    },
  },
};
</script>
<style scoped lang="less">
* {
  box-sizing: border-box;
  li {
    list-style: none;
  }
}
.none {
  visibility: hidden;
  // display: none;
}

.max_box {
  height: 1080px;
  // background: url("../../assets/img/nt/nt-bg1.png") no-repeat center center;
  // background-size: cover;
  // font-family: "Source Han Sans CN";
}
</style>